import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonsComponent, ButtonType, CheckboxComponent, DialogComponent } from '@my-tomorrows/components';
import { TERMS_OF_USE_LINK } from '@my-tomorrows/shared-data';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'myt-pre-screening-disclaimer-dialog',
  standalone: true,
  imports: [DialogComponent, TranslateModule, ButtonsComponent, CheckboxComponent],
  providers: [],
  templateUrl: './pre-screening-disclaimer-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreScreeningDisclaimerDialogComponent {
  readonly dialogRef = inject<MatDialogRef<PreScreeningDisclaimerDialogComponent>>(MatDialogRef);
  readonly termsOfUseLink = TERMS_OF_USE_LINK;

  readonly buttonType = ButtonType;
  readonly disclaimerCheck = signal(false);

  onCheck() {
    this.disclaimerCheck.update((i) => !i);
  }
}
