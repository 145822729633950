<myt-dialog [dialogActionsTemplate]="actionsTemplate" class="overflow-y-auto">
  <div class="mb-4">
    <p class="mb-2">{{ 'SEARCH.AI_PRESCREENING_RESULTS.DISCLAIMER_DESC.0' | translate }}</p>
    <myt-checkbox [checked]="disclaimerCheck()" (valueChanges)="onCheck()">
      <p class="text-regular pb-4 pl-1 pt-1 leading-6">{{ 'SEARCH.AI_PRESCREENING_RESULTS.DISCLAIMER_DESC.1' | translate }}</p>
      <div
        class="text-regular pl-1 leading-6"
        [innerHTML]="'SEARCH.AI_PRESCREENING_RESULTS.DISCLAIMER_DESC.2' | translate: { termsOfUseLink }"
      ></div>
    </myt-checkbox>
  </div>
</myt-dialog>

<ng-template #actionsTemplate>
  <div class="mt-2 flex justify-end gap-4 p-6">
    <myt-button [type]="buttonType.Basic" (buttonClick)="dialogRef.close()">{{
      'SEARCH.AI_PRESCREENING_RESULTS.DISCLAIMER_CANCEL' | translate
    }}</myt-button>
    <myt-button [type]="buttonType.Flat" (buttonClick)="dialogRef.close(true)" [disabled]="!disclaimerCheck()">{{
      'SEARCH.AI_PRESCREENING_RESULTS.DISCLAIMER_CONFIRM' | translate
    }}</myt-button>
  </div>
</ng-template>
