import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DEFAULT_DIALOG_CONFIGURATION } from '@my-tomorrows/components';
import { TranslateService } from '@ngx-translate/core';
import { PreScreeningDisclaimerDialogComponent } from '../pre-screening-disclaimer-dialog.component';

@Injectable()
export class PreScreeningDisclaimerDialogService {
  private readonly dialog = inject(MatDialog);
  private readonly translateService = inject(TranslateService);

  openPrescreeningDisclaimerDialog(): MatDialogRef<PreScreeningDisclaimerDialogComponent> {
    return this.dialog.open<PreScreeningDisclaimerDialogComponent>(PreScreeningDisclaimerDialogComponent, {
      ...DEFAULT_DIALOG_CONFIGURATION,
      width: '592px',
      restoreFocus: false,
      data: {
        title: this.translateService.instant('SEARCH.AI_PRESCREENING_RESULTS.DISCLAIMER_TITLE'),
        titleClassNames: '',
        contentClassNames: 'border-0',
      },
    });
  }
}
